import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'textarea[uidsTextareaAutoresize]',
  standalone: true,
})
export class TextareaAutoresizeDirective implements AfterViewInit {

  private readonly _BASE_LINE_HEIGHT = 24;
  private readonly _MIN_LINES = 7 / 3;
  private readonly _MAX_LINES = 14;

  constructor(private elementRef: ElementRef) { }

  @HostListener(':input')
  onInput() {
    this.resize();
  }

  ngAfterViewInit() {
    if (this.elementRef.nativeElement.scrollHeight) {
      this.resize();
    }
  }

  /**
   * Forzar resize de textarea:
   * Este método resetea el textarea a su altura mínima después de que se haya
   * limpiado la pila de ejecución actual. El timeout 0 garantiza que el DOM
   * ha procesado completamente el cambio de contenido.
   */
  public forceResize(): void {
    this.elementRef.nativeElement.style.height = '0';

    setTimeout(() => {
      const minHeight = this._MIN_LINES * this._BASE_LINE_HEIGHT;
      this.elementRef.nativeElement.style.height = `${minHeight}px`;
    }, 0);
  }

  private resize() {
    this.elementRef.nativeElement.style.height = '0';
    this.elementRef.nativeElement.style.height = Math.max(
      this._MIN_LINES,
      Math.min(this._MAX_LINES, Math.ceil(this.elementRef.nativeElement.scrollHeight / this._BASE_LINE_HEIGHT)),
    ) * this._BASE_LINE_HEIGHT + 'px';
  }

}
