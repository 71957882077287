<div class="main-chat-box-container">

  <small class="main-chat-box-disclaimer fromIESEKnowledgeWrapper chat-box-message-color">
    <i class="pi pi-cog"></i> If you don't want to work with IESE content, uncheck
      <p-checkbox class="fromIESEKnowledgeCheckbox" inputId="fromIESEKnowledge" [(ngModel)]="fromIESEKnowledge" [binary]="true" />
    <!-- <span [pTooltip]="'Use IESE Knowledge Database'" tooltipPosition="top" style="display: flex;">
      <label for="fromIESEKnowledge" class="fromIESEKnowledgeLabel">
        <svg style="height: 28px;" ieseLogotype class=""></svg></label>
      <p-checkbox class="fromIESEKnowledgeCheckbox" inputId="fromIESEKnowledge" [(ngModel)]="fromIESEKnowledge" [binary]="true" />
    </span> -->
  </small>
  <div class="main-chat-box-prompt">

    <textarea class="main-chat-box-input" placeholder="Enter your message..." rows="2"
      (keydown)="onKeydown($event)" [(ngModel)]="currentMessage"
      [disabled]="generatingMessage" uidsTextareaAutoresize
      [ngClass]="{ 'disabled-element': generatingMessage }">
    </textarea>

    <button *ngIf="!generatingMessage"
            class="iese-rounded-button primary"
            (click)="sendMessage()">
      <span class="pi pi-send"></span>
    </button>
    <button *ngIf="generatingMessage"
            class="iese-rounded-button primary"
            (click)="stopMessage()">
      <span class="pi pi-stop"></span>
    </button>
  </div>

  <small class="main-chat-box-disclaimer p-2 chat-box-message-color">
    Please keep in mind that ChatGPT can make mistakes, so human supervision is necessary.
  </small>
</div>
