import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import {
  CHAT_DATA_ACCESS_CONFIG,
  ChatDataAccessConfig,
  ChatDataActions,
  selectActiveChat,
  selectGeneratingMessage,
} from '@iese-chatbot/chat-data-access';
import { Subject, takeUntil } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { Chat, generateUUID, Role } from '@iese-chatbot/common-utils';
import { Router } from '@angular/router';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { TextareaAutoresizeDirective } from '@iese-chatbot/ui-design-system';

@Component({
  selector: 'icbc-main-chat-box',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CheckboxModule,
    TooltipModule,
    TextareaAutoresizeDirective,
  ],
  templateUrl: './main-chat-box.component.html',
})
export class MainChatBoxComponent implements OnInit, OnDestroy {

  @ViewChild(TextareaAutoresizeDirective) protected textareaResize!: TextareaAutoresizeDirective;

  protected mock = false;
  protected fromIESEKnowledge = true;
  protected currentMessage = '';
  protected activeChat: Chat | undefined;
  protected generatingMessage = false;
  private unsuscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(CHAT_DATA_ACCESS_CONFIG) private config: ChatDataAccessConfig,
    private store: Store,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.unsuscribe$.next(true);
    this.unsuscribe$.complete();
  }

  ngOnInit(): void {
    this.mock = this.config.mock;
    this.store.select(selectActiveChat).pipe(
      takeUntil(this.unsuscribe$),
    ).subscribe({
      next: activeChat => {
        this.activeChat = activeChat;
        this.fromIESEKnowledge = activeChat?.messages[activeChat?.messages.length - 1].fromIESEKnowledge ?? true;
      },
    });
    this.store.select(selectGeneratingMessage).pipe(
      takeUntil(this.unsuscribe$),
    ).subscribe({
      next: generatingMessage => { this.generatingMessage = generatingMessage; },
    });
  }

  protected sendMessage() {
    // TODO mover esto a un "builder" en `common-utils`?
    let message = this.currentMessage.trim();
    const stripEmptyLines = message.replace(/^\r\n$|^\r$|^\n$/g, '');
    if (stripEmptyLines.length === 0) {
      return;
    }
    message = message.replace(/\r\n|\r|\n/g, '<br />');

    if (message.length === 0) {
      return;
    }

    if (this.activeChat) {
      const chat = {
        ...this.activeChat,
        fromIESEKnowledge: this.fromIESEKnowledge,
        messages: [
          ...this.activeChat.messages,
          {
            role: 'user' as Role,
            content: message,
            finished: true,
            fromIESEKnowledge: this.fromIESEKnowledge,
          },
        ],
      };
      this.store.dispatch(ChatDataActions.addMessage({ chat, mock: this.mock }));
      this.emptyPrompt();
    } else {
      const chat: Chat = {
        id: generateUUID(),
        title: 'Newly generated chat',
        model: 'gpt-3.5-turbo',
        systemMessage: 'You are ChatGPT, a large language model trained by OpenAI.',
        temperature: .7,
        createdAt: new Date(),
        updatedAt: new Date(),
        fromIESEKnowledge: this.fromIESEKnowledge,
        messages: [
          {
            content: 'You are ChatGPT, a large language model trained by OpenAI.',
            role: 'system' as Role,
            finished: false,
            fromIESEKnowledge: this.fromIESEKnowledge,
          },
          {
            content: message,
            role: 'user' as Role,
            finished: true,
            fromIESEKnowledge: this.fromIESEKnowledge,
          },
        ],
      };
      this.store.dispatch(ChatDataActions.addChat({ chat, mock: this.mock, shortcut: null }));
      this.router.navigate(['', 'chat', chat.id]);
      this.emptyPrompt();
    }
  }

  protected stopMessage() {
    if (this.activeChat) {
      const payload = { chat: this.activeChat, manually: true };
      this.store.dispatch(ChatDataActions.completeMessageStream(payload));
    }
  }

  protected onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  private emptyPrompt() {
    this.currentMessage = '';
    this.textareaResize.forceResize();
  }

}
